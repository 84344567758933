import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import { layoutStyles } from "../styles/layoutStyles"
import { MeasureForm } from "../components/forms/MeasureForm"
import SEO from "../components/seo"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${layoutStyles.contentWidth};
  margin: 1rem auto;
  padding: ${layoutStyles.contentPadding};
`

const SecondPage = () => {
  return (
    <Layout>
      <SEO
        title="Best-Practices"
        keywords={[
          `IHK`,
          `Energeffizienznetzwerk`,
          `ETA`,
          "ETA-Plus",
          "IHK - Energeffizienznetzwerk",
        ]}
      />
      <Content />
      <MeasureForm />
    </Layout>
  )
}

export default SecondPage
